.tickets-loader {
    width: 40px;
    height: 84px;
    display: block;
    border-radius: 0 0 20px 20px;
    position: relative;
    background: #f1c40fa1 radial-gradient(circle 5px at 50% 85%, #FF3D00 100%, transparent 0);
    
  }
  .tickets-loader:before , .tickets-loader:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 84px;
    border-radius: 0 0 20px 20px;
    background: #f1c40f;
    opacity: 0.4;
    transform: rotate(60deg);
    transform-origin: 50% 85%;
    /* z-index: -2; */
    animation: rotate 1s infinite linear alternate;
  }
  .tickets-loader:after {
    animation: rotate2 1s infinite linear alternate;
    opacity: 0.5;
  }
  @keyframes rotate {
    0% , 20% { transform: rotate(0deg) }
    80% , 100% { transform: rotate(30deg) }
  }
  @keyframes rotate2 {
    0% , 20% { transform: rotate(0deg) }
    80% , 100% { transform: rotate(60deg) }
  }
